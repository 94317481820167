<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    solo
                                    dense
                                    v-model="customer"
                                    :items="customers"
                                    item-value="value"
                                    item-text="label"
                                    label="Customer Category"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="End Date"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    dense
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="Mill ID"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    dense
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grd"
                                    item-text="grd"
                                    label="Grade"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="tebal"
                                    :item-text="item => $store.getters.convertToCurrency(item.tebal)"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="lebar"
                                    :item-text="item => $store.getters.convertToCurrency(item.lebar)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    dense
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="NamaWarna"
                                    label="Colour"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    dense
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="grade"
                                    item-text="grade"
                                    label="Quality"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis" outlined height="500">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 450px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container6" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container7" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            selected: ['SC', 'FGPROD'],
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order & Coil Received Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [
                {
                    label: 'All',
                    value: 'All'
                },
                {
                    label: 'KKA',
                    value: 'KKA'
                },
                {
                    label: 'Non KKA',
                    value: 'Non KKA'
                }
            ],
            customer: '',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',     
            showData: true,
            showDataDetail: true,
            mills: [],
            mill: '',    
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.customer = 'All'
        await this.getMills()
        await this.getGrade()
        await this.getThick()
        await this.getWidth()
        await this.getColour()
        await this.getQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getMills(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/grade_brg_jadi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/tebal_brg_jadi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/lebar_brg_jadi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getColour(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/kka/master/color-id`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colours = res.data.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/quality_brg_jadi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        checkSelected(){
            if (this.selected.length > 2) {
                this.selected = []
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please max check 2 item",
                    visible: true
                };
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.selected.length < 2 || this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please check 2 item or fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''

            var reqBody = {
                'start': start,
                'end': end,
                'mill_id': this.mill,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            var data1 = {}
            var data2 = {}
            var data3 = {}
            var dt1 = [];
            var dt2 = [];
            var dt3 = [];

            var label = ''
            var container = 'container1';
            var title = " Summary";

            const respData = await backendApi.fetchCore('/v2/kka/analysis', reqBody, false, false, false)
            if (respData.status === 200) {

                label = 'Order, Receive PO and Non PO ';
                
                for (var i = 0; i < respData.data.byOrder.length; i++) {
                    dt1.push({ y: parseFloat(respData.data.byOrder[i].total_wgt), x: new Date(respData.data.byOrder[i].tr_date)});
                }
                for (var i = 0; i < respData.data.byReceivedPO.length; i++) {
                    dt2.push({ y: parseFloat(respData.data.byReceivedPO[i].total_wgt), x: new Date(respData.data.byReceivedPO[i].tr_date)});
                }
                for (var i = 0; i < respData.data.byReceivedNonPO.length; i++) {
                    dt3.push({ y: parseFloat(respData.data.byReceivedNonPO[i].total_wgt), x: new Date(respData.data.byReceivedNonPO[i].tr_date)});
                }

                data1 = {
                    type: "column",
                    click: this.onClick1,
                    name: "Total Order",
                    // indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    // indexLabelPlacement: "outside",  
                    // indexLabelOrientation: "horizontal",
                    color: "#70ad47",
                    // dataPoints: dt1
                }

                data2 = {
                    type: "column",
                    click: this.onClickReceivePO,
                    name: "Total Receive PO",
                    // indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    // indexLabelPlacement: "outside",  
                    // indexLabelOrientation: "horizontal",
                    color: "#fb9b05",
                    // dataPoints: dt2
                }

                data3 = {
                    type: "column",
                    click: this.onClickReceiveNonPO,
                    name: "Total Receive Non PO",
                    // indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    // indexLabelPlacement: "outside",  
                    // indexLabelOrientation: "horizontal",
                    color: "#a5a5a5",
                    // dataPoints: dt3
                }

                await this.getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3);

                $('html, body').animate({
                    scrollTop: $("#sumScShipmentAnalysis").offset().top
                }, 1200)

                this.showData = false

                this.$store.dispatch('setOverlay', false)
            }


            

        },
        async getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    labelFormatter: function (e) {
                        return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    },
                    interval: 3,
                    intervalType: "day"
                },
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [ data1, data2, data3 ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async onClick1(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'trans_date': (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) ,
                'mill_id': this.mill,
                'grade_id': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'color_id': this.colour ? this.colour : '',
                'quality_id': this.quality ? this.quality : '',
                'mach_type' : this.mach_type ? this.mach_type : '',
                'cycle' : 'DATE',
                'groupBy': 'mach_name'
            }

            const respDataMachType = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBody, false, false, false)

            var reqBodyThick = this.updateType(reqBody, 'thick')
            const respDataThick = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBodyThick, false, false, false)

            var reqBodyWidth = this.updateType(reqBody, 'width')
            const respDataWidth = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBodyWidth, false, false, false)

            var reqBodyGrade = this.updateType(reqBody, 'grade_id')
            const respDataGrade = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBodyGrade, false, false, false)

            var reqBodyColor = this.updateType(reqBody, 'NamaWarna')
            const respDataColor = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBodyColor, false, false, false)

            var reqBodyQuality = this.updateType(reqBody, 'quality_id')
            const respDataQuality = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance', reqBodyQuality, false, false, false)

            if (respDataMachType.status === 200 && respDataThick.status === 200 && respDataWidth.status === 200 && respDataGrade.status === 200 && respDataColor.status === 200 && respDataQuality.status === 200) {

                var param = (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                var label_detail = "Trans Date: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respDataMachType.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataMachType.data.data.length; i++) {

                        dt_detail.push({  label: respDataMachType.data.data[i].mach_name,  y: parseInt(respDataMachType.data.data[i].total_wgt), legendText: respDataMachType.data.data[i].mach_name });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Machine Type Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataThick.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick),  y: parseInt(respDataThick.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick) });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thick Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataWidth.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataWidth.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width),  y: parseInt(respDataWidth.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width) });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Width Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataGrade.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataGrade.data.data.length; i++) {

                        dt_detail.push({  label: respDataGrade.data.data[i].grade_id,  y: parseInt(respDataGrade.data.data[i].total_wgt), legendText: respDataGrade.data.data[i].grade_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Grade ID Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataColor.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataColor.data.data.length; i++) {

                        dt_detail.push({  label: respDataColor.data.data[i].NamaWarna,  y: parseInt(respDataColor.data.data[i].total_wgt), legendText: respDataColor.data.data[i].NamaWarna });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Color Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataQuality.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data.data[i].quality_id,  y: parseInt(respDataQuality.data.data[i].total_wgt), legendText: respDataQuality.data.data[i].quality_id });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Quality Order'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)

                
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClickReceivePO(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'trans_date': (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) ,
                'mill_id': this.mill,
                'grade_id': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'color_id': this.colour ? this.colour : '',
                'quality_id': this.quality ? this.quality : '',
                'mach_type' : this.mach_type ? this.mach_type : '',
                'type' : 'PO',
                'groupBy': 'thick'
            }
            
            const respDataThick = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBody, false, false, false)

            var reqBodyWidth = this.updateType(reqBody, 'width')
            const respDataWidth = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyWidth, false, false, false)

            var reqBodyGrade = this.updateType(reqBody, 'grade_id')
            const respDataGrade = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyGrade, false, false, false)

            var reqBodyColor = this.updateType(reqBody, 'NamaWarna')
            const respDataColor = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyColor, false, false, false)

            var reqBodyQuality = this.updateType(reqBody, 'quality_id')
            const respDataQuality = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyQuality, false, false, false)

            if (respDataThick.status === 200 && respDataWidth.status === 200 && respDataGrade.status === 200 && respDataColor.status === 200 && respDataQuality.status === 200) {

                var param = (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                var label_detail = "Trans Date: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respDataThick.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick),  y: parseInt(respDataThick.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick) });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Thick Coil Receive PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataWidth.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataWidth.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width),  y: parseInt(respDataWidth.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width) });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Width Coil Receive PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataGrade.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataGrade.data.data.length; i++) {

                        dt_detail.push({  label: respDataGrade.data.data[i].grade_id,  y: parseInt(respDataGrade.data.data[i].total_wgt), legendText: respDataGrade.data.data[i].grade_id });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Grade ID Coil Receive PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataColor.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataColor.data.data.length; i++) {

                        dt_detail.push({  label: respDataColor.data.data[i].NamaWarna,  y: parseInt(respDataColor.data.data[i].total_wgt), legendText: respDataColor.data.data[i].NamaWarna });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Color Coil Receive PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataQuality.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data.data[i].quality_id,  y: parseInt(respDataQuality.data.data[i].total_wgt), legendText: respDataQuality.data.data[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality Coil Receive PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)

                
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClickReceiveNonPO(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'trans_date': (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) ,
                'mill_id': this.mill,
                'grade_id': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'color_id': this.colour ? this.colour : '',
                'quality_id': this.quality ? this.quality : '',
                'mach_type' : this.mach_type ? this.mach_type : '',
                'type' : 'NP',
                'groupBy': 'thick'
            }
            
            const respDataThick = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBody, false, false, false)

            var reqBodyWidth = this.updateType(reqBody, 'width')
            const respDataWidth = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyWidth, false, false, false)

            var reqBodyGrade = this.updateType(reqBody, 'grade_id')
            const respDataGrade = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyGrade, false, false, false)

            var reqBodyColor = this.updateType(reqBody, 'NamaWarna')
            const respDataColor = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyColor, false, false, false)

            var reqBodyQuality = this.updateType(reqBody, 'quality_id')
            const respDataQuality = await backendApi.fetchCore('/api/v3/kka/analysis/order-to-ship-perfomance/detail-performance-receive', reqBodyQuality, false, false, false)

            if (respDataThick.status === 200 && respDataWidth.status === 200 && respDataGrade.status === 200 && respDataColor.status === 200 && respDataQuality.status === 200) {

                var param = (new Date(e.dataPoint.x - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                var label_detail = "Trans Date: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respDataThick.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick),  y: parseInt(respDataThick.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyUs(respDataThick.data.data[i].thick) });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Thick Coil Receive Non PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataWidth.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataWidth.data.data.length; i++) {

                        dt_detail.push({  label: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width),  y: parseInt(respDataWidth.data.data[i].total_wgt), legendText: this.$store.getters.convertToCurrencyNoFrac(respDataWidth.data.data[i].width) });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Width Coil Receive Non PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataGrade.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataGrade.data.data.length; i++) {

                        dt_detail.push({  label: respDataGrade.data.data[i].grade_id,  y: parseInt(respDataGrade.data.data[i].total_wgt), legendText: respDataGrade.data.data[i].grade_id });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Grade ID Coil Receive Non PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataColor.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataColor.data.data.length; i++) {

                        dt_detail.push({  label: respDataColor.data.data[i].NamaWarna,  y: parseInt(respDataColor.data.data[i].total_wgt), legendText: respDataColor.data.data[i].NamaWarna });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Color Coil Receive Non PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataQuality.data.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data.data[i].quality_id,  y: parseInt(respDataQuality.data.data[i].total_wgt), legendText: respDataQuality.data.data[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality Coil Receive Non PO'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)

                
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();
        },
        updateType(reqBody, type){
            reqBody.groupBy = type
            return reqBody
        },
    },
    watch: {
    }
}
</script>
    